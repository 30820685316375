import React, { useCallback, useEffect, useState } from 'react';

import { Box, Grid, Modal, useMediaQuery } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import {
  CmpButton,
  CmpCheckboxField,
  CmpInputField,
  CmpSelectField,
  CmpStatusDiv,
  CmpTypographyField,
  CmpDatePickerField,
  CmpSelectArrField
} from '../../component/ToolBox/ToolBox';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { fAlertToast } from '../../Utility/Utilitys';
import moment from 'moment';

import { updateTransHead } from './PurchaseSlice';
import {
  getTransItems,
  getVendorList,
  getVendorOSAmt,
  reversePurchaseTransaction,
  savePurchaseTransaction,
  searchVendor
} from './actions';
import TransItemEditableGrid from './ProcessComponents/TransItemEditableGrid';
import OpenPurchaseBreadcrumbs from './ProcessComponents/OpenPurchaseBreadcrumbs';
import PurchasePostModal from './ProcessComponents/PurchasePostModal';
import './Purchase.css';
import AddNewItemModal from './ProcessComponents/AddNewItemModal';
import GCDropdownSearch from '../../component/ReuseableComponent/GCDropdownSearch';
export default function PurchaseDetail() {
  const dispatch = useDispatch();
  const rdxTransHead = useSelector((state) => state.PurchaseSlice.transHead);
  const rdxFilterObj = useSelector((state) => state.layoutSlice.filter);
  const cPurchaseFlg = useSelector((state) => state.SliceDB.value.centralpurchase);
  const [sHeadObj, setHeadObj] = useState(rdxTransHead);
  const [sItemList, setItemList] = useState([]);
  const [sVendorList, setVendorList] = useState([]);
  const [sSelectItemChecked, setSelectItemChecked] = useState(false);
  const [sHideItemChecked, setHideItemChecked] = useState(
    rdxTransHead.Status === 'POST' ? false : true
    // for checkbox Hide Purchase Items
  );
  const [sFilterdItemList, setFilterdItemList] = useState([]);
  const [sTotalObj, setTotalObj] = useState({
    Taxable: 0,
    Tax: 0,
    Total: 0,
    NetAmt: 0
  });
  const [sGridEditing, setGridEditing] = useState(false);
  const [sShowPostModal, setShowPostModal] = useState(false);
  const [sShowAddItemModal, setShowAddItemModal] = useState(false);
  const paymodeList = ['CREDIT', 'CASH'];
  const vMediaQuery = useMediaQuery('(max-width:600px)');

  const loadItems = useCallback(async () => {
    const obj = {
      transId: rdxTransHead.TransId,
      orderId: rdxTransHead.OrderId,
      itmCd: ''
    };
    const data = await getTransItems(obj);
    console.log('api resulttttt' + JSON.stringify(data));
    //  setItemList(data.ResultSet.map(i => { return { ...i, id: i.SrNo, click: '...' } }));

    /************** new code to calculted loc wise ctn *********** */
    let modifiedArr = data.ResultSet.map((i) => {
      let packQty = isNaN(i.PackQty) ? 1 : i.PackQty === 0 ? 1 : i.PackQty;

      let updatedLocList = i.locList.map((j) => {
        let reqCTN = 0;
        if (j.OrderQty - j.ExistingAlloQty <= 0) {
          reqCTN = 0;
        } else {
          reqCTN = Math.round((j.OrderQty - j.ExistingAlloQty) / packQty);
          reqCTN = reqCTN === 0 ? (j.ExistingAlloQty > 0 ? 0 : 1) : reqCTN;
        }

        return {
          ...j,
          OrderQtyCTN: j.OrderQty / packQty,
          ExistingAlloQtyCTN: j.ExistingAlloQty / packQty,
          ReqCTN: reqCTN,
          selected: true
        };
      });

      /************ updating item level req ctn giving error********** */
      let RequiredCTN = updatedLocList.reduce((prevValue, k) => {
        return prevValue + k.ReqCTN;
      }, 0);

      return {
        ...i,
        id: i.SrNo,
        click: '...',
        locList: updatedLocList,
        RequiredCTN: RequiredCTN
      };
    });
    setItemList(modifiedArr);

    console.log('modifiedArr>>>>>>>>>>>>>>>>>>>>>>>>>>>' + JSON.stringify(modifiedArr));

    /******************************************/
  }, [setItemList, rdxTransHead]);

  // const getVendors = useCallback(async () => {
  //     const vVendor = await getVendorList({});
  //     setVendorList(vVendor.data.ResultSet);
  // }, [setVendorList]);

  // const getVendorOSAmount = useCallback(async (vendor) => {
  //     const result = await getVendorOSAmt({
  //         AcCd: vendor,
  //         Loc: rdxFilterObj.DCList.toString()
  //     });
  //     dispatch(updateTransHead({ ...rdxTransHead, TRNS_Vendor_Os: Number(result.data?.ResultSet[0].OSAmt) }));

  // }, [rdxFilterObj, rdxTransHead, dispatch]);

  const getVendorOSAmount = async (vendor, name, taxable) => {
    const result = await getVendorOSAmt({
      AcCd: vendor,
      Loc: rdxFilterObj.DCList.toString()
    });
    // dispatch(updateTransHead({ ...rdxTransHead, TRNS_Vendor_Os: Number(result.data?.ResultSet[0].OSAmt) }));
    //const vendorName = sVendorList.find((i) => i.AcCd === vendor)?.Name2;
    setHeadObj({
      ...sHeadObj,
      Vendor: vendor,
      VendorName: name,
      TRNS_Vendor_Os: Number(result.data?.ResultSet[0].OSAmt),
      TaxPurchase: taxable
    });
  };

  useEffect(() => {
    loadItems();
    //getVendors();
  }, [loadItems]);

  useEffect(() => {
    //  let arr = sSelectItemChecked || sHideItemChecked ? sFilterdItemList : sItemList;
    let arr = sItemList;
    let taxable = arr.reduce((prevValue, i) => {
      return prevValue + Number(i.Taxable);
    }, 0);
    let tax = arr.reduce((prevValue, i) => {
      return prevValue + Number(i.TaxAmt) + Number(i.TaxAmt2) + Number(i.Ad_NetCessAmt);
    }, 0);

    setTotalObj({
      Taxable: taxable,
      Tax: tax,
      Total: Number(taxable) + Number(tax),
      // NetAmt: Number(taxable) + Number(tax) + Number(rdxTransHead.Rounding),
      NetAmt: Number(taxable) + Number(tax) + Number(sHeadObj.Rounding)
    });
  }, [sSelectItemChecked, sFilterdItemList, sItemList, sHeadObj]);

  const fFilterSelectedItems = () => {
    setSelectItemChecked(!sSelectItemChecked);

    let arr = sHideItemChecked ? sItemList.filter((i) => i.ExistingPur < i.OrdQty) : sItemList;
    setFilterdItemList(arr.filter((i) => i.Qty > 0));
  };
  const fFilterPurchasedItems = () => {
    setHideItemChecked(!sHideItemChecked);
    let arr = sSelectItemChecked ? sItemList.filter((i) => i.Qty > 0) : sItemList;
    setFilterdItemList(arr.filter((i) => i.ExistingPur < i.OrdQty));
  };

  // const xOnChangeVendor = (event) => {

  //     getVendorOSAmount(event.target.value);
  //     // dispatch(updateTransHead({ ...rdxTransHead, Vendor: event.target.value }));
  //     //setHeadObj({ ...sHeadObj, Vendor: event.target.value });

  //     //api call to load vendor O/s
  // }

  const xOnChangeVendor = (row) => {
    const taxable =
      sItemList.filter((i) => Number(i.Qty) > 0).length === 0 ? row.Taxable : sHeadObj.TaxPurchase;
    console.log(row, taxable);

    getVendorOSAmount(row.AcCd, row.Name2, taxable);
    // dispatch(updateTransHead({ ...rdxTransHead, Vendor: event.target.value }));
    //setHeadObj({ ...sHeadObj, Vendor: event.target.value });
  };

  const Validation = () => {
    if (sHeadObj.Vendor === '') {
      fAlertToast('FAILED', 'Select Vendor');
      return false;
    }
    if (sHeadObj.Paymode !== 'CASH' && sHeadObj.Paymode !== 'CREDIT') {
      fAlertToast('FAILED', 'Select Paymode');
      return false;
    }

    const qtyList = sItemList.filter((i) => i.Qty > 0);
    if (qtyList.length === 0) {
      fAlertToast('FAILED', 'Purchase quantity can not be 0 for all items');
      return false;
    }

    if (sTotalObj.Total < 0) {
      fAlertToast('FAILED', 'Total amount should not be less than 0');
      return false;
    }

    const allocList = sItemList.map((i) => {
      return {
        itmCd: i.ItmCd,
        Qty: i.Qty,
        AllocQty: i.locList.reduce((prevValue, i) => {
          return Number(prevValue) + Number(i.CurrAllocQtyCTN);
        }, 0)
      };
    });

    console.log('alloc qty checking' + JSON.stringify(allocList));

    const mismatchRows = allocList.filter((i) => Number(i.Qty) !== Number(i.AllocQty));
    if (mismatchRows.length > 0) {
      const itmlist = mismatchRows.map((i) => {
        return i.itmCd;
      });
      fAlertToast(
        'FAILED',
        'Location wise allocation is not matches with purchased qty for items(' +
          itmlist.toString() +
          ')'
      );
      return false;
    }

    const negativeQtyList = sItemList.filter((i) => i.Qty < 0);
    if (negativeQtyList.length > 0) {
      fAlertToast('FAILED', 'Purchase quantity can not be negative ');
      return false;
    }

    const negativePackQtyList = sItemList.filter((i) => i.PackQty < 0);
    if (negativePackQtyList.length > 0) {
      fAlertToast('FAILED', 'Pack quantity can not be negative ');
      return false;
    }
    const negativeUrateList = sItemList.filter((i) => i.UnitRate < 0);
    if (negativeUrateList.length > 0) {
      fAlertToast('FAILED', 'Unit rate can not be negative ');
      return false;
    }

    return true;
  };

  const PostValidation = () => {
    console.log(sHeadObj.invoiceAmt, Number(sTotalObj.Total) + Number(sHeadObj.Rounding));
    if (
      Number(sHeadObj.invoiceAmt).toFixed(2) !==
      (Number(sTotalObj.Total) + Number(sHeadObj.Rounding)).toFixed(2)
    ) {
      fAlertToast('FAILED', 'Invoice amount not matches with grand total');
      return false;
    }
    if (sHeadObj.TRNS_Confirm_Inv === 'Y' && sHeadObj.ReferenceNo === '') {
      fAlertToast('FAILED', 'Enter Invoice Number');
      return false;
    }
    if (sHeadObj.Paymode !== 'CASH' && sHeadObj.TRNS_Vendor_Reduction > 0) {
      fAlertToast('FAILED', 'Adjustment is only possible for paymode cash');
      return false;
    }
    return true;
  };

  const fSave = async () => {
    let purchasedItems = sItemList.filter((i) => i.Qty > 0);
    let itemList = purchasedItems.map((i) => {
      return {
        TRNS_SRNO: 0,
        TRNS_REQ_ITMCD: i.ItmCd,
        TRNS_ACT_ITMCD: i.ALTItmCd,
        TRNS_QTY: i.Qty,
        TRNS_UNIT: i.Unit,
        TRN_PACK_QTY: i.PackQty,
        TRNS_BASEQTY: i.BaseQty,
        TRNS_UnitRate: i.UnitRate,
        TRNS_GrossAmt: i.GrossAmt,
        TRNS_LDiscPerc: i.LDiscPerc,
        TRNS_LDiscAmt: i.LDiscAmt,
        TRNS_TDiscPerc: i.TDiscPerc,
        TRNS_TDiscAmt: i.TDiscAmt,
        TRNS_FocFlag: i.FOC,
        TRNS_AprmtFlag: i.AprmtFlag,
        TRNS_FocAmt: i.FocAmt,
        TRNS_NetAmt: i.Taxable,
        TRNS_TaxDept: i.TaxDept,
        TRNS_TaxPerc: i.TaxPerc,
        TRNS_TaxAmt: i.TaxAmt,
        TRNS_TaxDept2: i.TaxDept2,
        TRNS_TaxPerc2: i.TaxPerc2,
        TRNS_TaxAmt2: i.TaxAmt2,
        TRNS_ExciseTaxAmt: i.ExciseTaxAmt,
        TRNS_ExciseTaxNetAmt: i.ExciseTaxNetAmt,
        TRNS_Ad_CessAmt: i.Ad_CessAmt,
        TRNS_Ad_PCQty: i.Ad_PCQty,
        TRNS_Ad_PCRate: i.Ad_PCRate,
        TRNS_Ad_NetCessAmt: i.Ad_NetCessAmt,
        TRNS_LandVal: i.Total,
        VendorPackQty: i.VendorPackQty,
        locList: i.locList.map((j) => {
          return {
            TL_LOC_CD: j.Loc,
            TL_REQ_QTY: Number(j.ReqCTN) * Number(i.PackQty),
            TL_CURR_QTY: j.CurrAllocQtyCTN,
            TL_UNIT: i.Unit,
            TL_PACK_QTY: i.PackQty,
            TL_BASE_QTY: 0,
            TL_VAN_ID: j.VANID,
            TL_VAN_Name: ''
          };
        })
      };
    });
    let obj = {
      TransId: sHeadObj.TransId,
      orderId: sHeadObj.OrderId,
      Location: sHeadObj.Location,
      Date: moment(sHeadObj.Date).format('MM/DD/YYYY'),
      Party: sHeadObj.Vendor,
      RefNo: sHeadObj.ReferenceNo,
      RefDate: moment(sHeadObj.ReferenceDate).format('MM/DD/YYYY'),
      paymode: sHeadObj.Paymode,
      invoiceAmt: sHeadObj.invoiceAmt,
      status: sHeadObj.Status,
      rounding: sHeadObj.Rounding,
      TRNS_Vendor_Os: sHeadObj.TRNS_Vendor_Os,
      TRNS_Vendor_Os_Adj: sHeadObj.TRNS_Vendor_Os_Adj,
      TRNS_Vendor_Reduction: sHeadObj.TRNS_Vendor_Reduction,
      TRNS_Confirm_Inv: sHeadObj.TRNS_Confirm_Inv,
      TRNS_Direct_Deli: sHeadObj.TRNS_Direct_Deli,
      TRNS_PUR: sHeadObj.TaxPurchase,
      TRNS_PARTY_MOBILE: sHeadObj.TRNS_PARTY_MOBILE,
      itmlist: itemList
    };

    const result = await savePurchaseTransaction(obj);
    console.log('result>>>>' + JSON.stringify(result));

    if (result.status === 200) {
      fAlertToast('SUCCESS', 'Saved Successfully');
      let transId = result.data.ResultSet[0]?.TransId;
      dispatch(updateTransHead({ ...sHeadObj, TransId: transId }));
      setHeadObj({ ...sHeadObj, TransId: transId });
    }
  };
  const fSavePurcchaseTrans = async () => {
    if (Validation()) {
      confirmAlert({
        title: 'Save',
        message: 'Are you sure to Save?',
        buttons: [
          {
            className: 'alertBtn',
            label: 'Yes',
            onClick: async () => {
              fSave();
            }
          },
          {
            className: 'alertBtn',
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    }
  };

  const fPostPurcchaseTrans = async () => {
    if (Validation()) {
      if (PostValidation()) {
        confirmAlert({
          title: 'Save',
          message: 'Are you sure to Save?',
          buttons: [
            {
              className: 'alertBtn',
              label: 'Yes',
              onClick: async () => {
                await fSave();
                setShowPostModal(true);
              }
            },
            {
              className: 'alertBtn',
              label: 'No',
              onClick: () => {}
            }
          ]
        });
      }
    }
  };

  const fReversePurchaseTrans = async () => {
    confirmAlert({
      title: 'Reverse',
      message: 'Are you sure to Reverse?',
      buttons: [
        {
          className: 'alertBtn',
          label: 'Yes',
          onClick: async () => {
            let obj = {
              transId: sHeadObj.TransId
            };
            const result = await reversePurchaseTransaction(obj);
            if (result.data.Type === 'Success') {
              fAlertToast('SUCCESS', 'Reversed Successfully');
              dispatch(updateTransHead({ ...sHeadObj, Status: 'ENTER' }));
            }
          }
        },
        {
          className: 'alertBtn',
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const fSearchVendor = async (searchText) => {
    const obj = {
      searchText: searchText
    };

    const result = await searchVendor(obj);
    const resultSet = result.data ? result.data.ResultSet : [];
    return resultSet
      ? resultSet.map((i) => {
          return { ...i, id: i.AcCd };
        })
      : [];
  };
  const xVendorColumns = [
    {
      field: 'AcCd',
      headerName: 'Code',
      flex: 1
    },
    {
      field: 'Name2',
      headerName: 'Party Name',
      flex: 6
    }
  ];
  const onChangePaymode = (event) => {
    if (event.target.value === 'CREDIT') {
      setHeadObj({
        ...sHeadObj,
        Paymode: event.target.value,
        TRNS_Vendor_Reduction: 0,
        TRNS_Vendor_Os_Adj: 'N'
      });
    } else {
      setHeadObj({ ...sHeadObj, Paymode: event.target.value });
    }
  };
  return (
    <div className="container">
      <div>
        <ToastContainer />
      </div>
      <div>
        <OpenPurchaseBreadcrumbs xState={rdxTransHead.State} />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className="mb-1">
          <Grid item xs={2.5} sm={1.3} lg={1} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={fSavePurcchaseTrans}
              xLabel={'Save'}
              xDisable={rdxTransHead.Status.toString().toUpperCase() !== 'ENTER' || sGridEditing}
            />
          </Grid>
          <Grid item xs={2.5} sm={1.3} lg={1} md={1}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={fPostPurcchaseTrans}
              xLabel={'Post'}
              xDisable={rdxTransHead.Status.toString().toUpperCase() !== 'ENTER' || sGridEditing}
            />
          </Grid>
          <Grid item xs={3} sm={1.7} lg={1.2} md={1.3}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={fReversePurchaseTrans}
              xLabel={'Reverse'}
              xDisable={rdxTransHead.Status.toString().toUpperCase() !== 'POST' || sGridEditing}
            />
          </Grid>
          <Grid item xs={8} sm={4} lg={2} md={2}>
            <CmpButton
              xvariant={'contained'}
              xsize={'small'}
              xonClick={() => {
                setShowAddItemModal(true);
              }}
              xLabel={'Add New Item'}
              xDisable={rdxTransHead.Status.toString().toUpperCase() !== 'ENTER' || sGridEditing}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0.2}
          // rowSpacing={0}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          className="my-1"
          sx={{ alignItems: 'center' }}>
          <Grid item xs={8} sm={8} lg={8} md={8}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Trans ID'} />
              </Grid>
              <Grid item xs={0.5} sm={0.3} lg={0.3} md={0.3}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} lg={9} md={9}>
                <CmpTypographyField xcomponent={'span'} xText={rdxTransHead.TransId} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4} lg={4} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={7} sm={7} lg={5} md={5}>
                <CmpTypographyField xcomponent={'span'} xText={'Direct Invoice'} />
              </Grid>
              <Grid item xs={1} sm={0.7} lg={0.3} md={0.3}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={2} sm={2} lg={1} md={1}>
                {/* <CmpCheckboxField xChecked={rdxTransHead.TRNS_Confirm_Inv === "Y"}
                                    xOnChange={(event) => { dispatch(updateTransHead({ ...rdxTransHead, TRNS_Confirm_Inv: event.target.checked ? 'Y' : 'N' })) }}
                                /> */}
                <CmpCheckboxField
                  xChecked={sHeadObj.TRNS_Confirm_Inv === 'Y'}
                  xOnChange={(event) => {
                    setHeadObj({
                      ...sHeadObj,
                      TRNS_Confirm_Inv: event.target.checked ? 'Y' : 'N'
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} sm={8} lg={8} md={8}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Order No.'} />
              </Grid>
              <Grid item xs={0.5} sm={0.3} lg={0.3} md={0.3}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} lg={9} md={9}>
                <CmpTypographyField xcomponent={'span'} xText={rdxTransHead.OrderId} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4} sm={4} lg={4} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={7} sm={7} lg={5} md={5}>
                <CmpTypographyField xcomponent={'span'} xText={'DC Purchase'} />
              </Grid>
              <Grid item xs={1} sm={0.7} lg={0.3} md={0.3}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={2} sm={2} lg={1} md={1}>
                <CmpCheckboxField xChecked={sHeadObj.DCPurchase === 'Y'} xDisabled={true} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} sm={8} lg={8} md={8}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={4} sm={4} lg={2} md={2}>
                <CmpTypographyField
                  xcomponent={'span'}
                  xText={
                    rdxTransHead?.CNTRL_PUR == 'Y' && cPurchaseFlg
                      ? 'Central Purchase'
                      : 'Direct Transfer to Branch'
                  }
                />
              </Grid>
              <Grid item xs={0.5} sm={0.3} lg={0.3} md={0.3}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={7} sm={7} lg={9} md={9}>
                <CmpCheckboxField xChecked={true} xDisabled={true} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4} lg={4} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={7} sm={7} lg={5} md={5}>
                <CmpTypographyField xcomponent={'span'} xText={'Tax Purchase'} />
              </Grid>
              <Grid item xs={1} sm={0.7} lg={0.3} md={0.3}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={2} sm={2} lg={1} md={1}>
                {/* <CmpCheckboxField xChecked={rdxTransHead.TaxPurchase === "Y"}
                                    xOnChange={(event) => { dispatch(updateTransHead({ ...rdxTransHead, TaxPurchase: event.target.checked ? 'Y' : 'N' })) }}

                                /> */}
                <CmpCheckboxField
                  xChecked={sHeadObj.TaxPurchase === 'Y'}
                  xOnChange={(event) => {
                    setHeadObj({ ...sHeadObj, TaxPurchase: event.target.checked ? 'Y' : 'N' });
                  }}
                  xDisabled={sItemList.filter((i) => Number(i.Qty) > 0).length > 0}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={8} lg={8} md={8}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.65} sm={4} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Mobile & Paymode'} />
              </Grid>
              <Grid item xs={0.5} sm={0.3} lg={0.3} md={0.3}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={4} sm={4} lg={3.5} md={3.5}>
                {/* <CmpInputField
                                    xValue={rdxTransHead.TRNS_PARTY_MOBILE}
                                    xType={'text'} // text/number/password
                                    xName={'RefNo'}
                                    xReadOnly={false}
                                    xOnChange={(event) => { dispatch(updateTransHead({ ...rdxTransHead, TRNS_PARTY_MOBILE: event.target.value })) }}
                                    xError={false}
                                    xErrorMessage={''}
                                    xOnKeyUp={''}
                                /> */}
                <CmpInputField
                  xValue={sHeadObj.TRNS_PARTY_MOBILE}
                  xType={'text'} // text/number/password
                  xName={'RefNo'}
                  xReadOnly={false}
                  xOnChange={(event) => {
                    setHeadObj({ ...sHeadObj, TRNS_PARTY_MOBILE: event.target.value });
                  }}
                  xError={false}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                />
              </Grid>
              <Grid item xs={4.5} sm={3} lg={3.5} md={3.5}>
                <CmpSelectArrField
                  xLabelText="Paymode"
                  xValue={sHeadObj.Paymode}
                  xOnChange={(event) => {
                    onChangePaymode(event);
                  }}
                  xName={'Paymode'}
                  xData={paymodeList}
                  xValueMember={''}
                  xDispalyMember={''}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={false}
                  xTabIndex={'2'}
                />
              </Grid>
            </Grid>
          </Grid>
          {!vMediaQuery && (
            <Grid item xs={4} sm={4} lg={4} md={4}>
              <Grid
                container
                spacing={1}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={7} sm={7} lg={5} md={5}>
                  <CmpTypographyField xcomponent={'span'} xText={'Selected Items'} />
                </Grid>
                <Grid item xs={1} sm={0.7} lg={0.3} md={0.3}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={2} sm={2} lg={1} md={1}>
                  <CmpCheckboxField
                    xChecked={sSelectItemChecked}
                    xOnChange={() => fFilterSelectedItems()}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} sm={12} lg={8} md={8}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.65} sm={2.65} lg={2} md={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Vendor'} />
              </Grid>
              <Grid item xs={0.5} sm={0.3} lg={0.3} md={0.3}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={8.5} sm={8} lg={7} md={7}>
                {/* <CmpSelectField
                                    xTabIndex="5"
                                    xLabelText="Vendor"
                                    // xValue={rdxTransHead.Vendor}
                                    xValue={sHeadObj.Vendor}
                                    xOnChange={(event) => { xOnChangeVendor(event) }}
                                    xName={'Vendor'}
                                    xData={sVendorList}
                                    xValueMember={''}
                                    xDispalyMember={''}
                                    xError={false}
                                    xErrorMessage={false}
                                    xDisable={rdxTransHead.Status.toString().toUpperCase() !== 'ENTER'}
                                /> */}
                <GCDropdownSearch
                  xValue={sHeadObj.Vendor}
                  xOnChange={(params) => {
                    xOnChangeVendor(params.row);
                  }}
                  xType="text"
                  xName="vendor"
                  xTextAlign="left"
                  xTabIndex="1"
                  xSearchText={sHeadObj.VendorName}
                  xSetSearchText={(value) => {
                    setHeadObj({ ...sHeadObj, VendorName: value });
                  }}
                  xOnBackSpaceKeyPress={() =>
                    setHeadObj({ ...sHeadObj, Vendor: '', vendorName: '' })
                  }
                  xResultColumns={xVendorColumns}
                  xLoadDetail={async (searchText) => fSearchVendor(searchText)}
                  xError={false}
                  xErrorMessage={false}
                  xDisable={rdxTransHead.Status.toString().toUpperCase() !== 'ENTER'}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={4} sm={4} lg={4} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={7} sm={7} lg={5} md={5}>
                <CmpTypographyField xcomponent={'span'} xText={'Direct Delivery'} />
              </Grid>
              <Grid item xs={1} sm={0.7} lg={0.3} md={0.3}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={2} sm={2} lg={1} md={1}>
                <CmpCheckboxField
                  xChecked={sHeadObj.TRNS_Direct_Deli === 'Y'}
                  xOnChange={(event) => {
                    setHeadObj({
                      ...sHeadObj,
                      TRNS_Direct_Deli: event.target.checked ? 'Y' : 'N'
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item xs={12} sm={12} lg={8} md={8}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              sx={{ alignItems: 'center' }}>
              <Grid item xs={2.65} sm={2.65} md={2} lg={2}>
                <CmpTypographyField xcomponent={'span'} xText={'Inv Date/No/Amt.'} />
              </Grid>
              <Grid item xs={0.5} sm={0.3} md={0.3} lg={0.3}>
                <CmpTypographyField xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={3} sm={2} md={2} lg={2}>
                <CmpDatePickerField
                  xTabIndex="2"
                  //xValue={rdxTransHead.ReferenceDate}
                  // xOnChange={(newValue) => { dispatch(updateTransHead({ ...rdxTransHead, ReferenceDate: newValue.$d })) }}
                  xValue={sHeadObj.ReferenceDate}
                  xOnChange={(newValue) => {
                    setHeadObj({ ...sHeadObj, ReferenceDate: newValue.$d });
                  }}
                  xName={'ReferenceDate'}
                  xError={''}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                />
              </Grid>
              <Grid item xs={2.5} sm={3} md={2} lg={2}>
                <CmpInputField
                  xType={'text'} // text/number/password
                  xName={'RefNo'}
                  xReadOnly={false}
                  // xValue={rdxTransHead.ReferenceNo}
                  // xOnChange={(event) => { dispatch(updateTransHead({ ...rdxTransHead, ReferenceNo: event.target.value })) }}
                  xValue={sHeadObj.ReferenceNo}
                  xOnChange={(event) => {
                    setHeadObj({ ...sHeadObj, ReferenceNo: event.target.value });
                  }}
                  xError={false}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <CmpInputField
                  xType={'number'} // text/number/password
                  xName={'Rounding'}
                  xReadOnly={false}
                  // xValue={rdxTransHead.invoiceAmt}
                  // xOnChange={(event) => { dispatch(updateTransHead({ ...rdxTransHead, invoiceAmt: event.target.value })) }}
                  xValue={sHeadObj.invoiceAmt}
                  xOnChange={(event) => {
                    setHeadObj({ ...sHeadObj, invoiceAmt: event.target.value });
                  }}
                  xError={false}
                  xErrorMessage={''}
                  xOnKeyUp={''}
                  xTextAlign="center"
                />
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item xs={4} sm={4} lg={4} md={4}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                            sx={{ alignItems: 'center' }}>
                            <Grid item xs={7} sm={7} lg={5} md={5}>
                                <CmpTypographyField xcomponent={'span'} xText={'Selected Items'} />
                            </Grid>
                            <Grid item xs={1} sm={1} lg={1} md={1}>
                                <CmpTypographyField xcomponent={'span'} xText={':'} />
                            </Grid>
                            <Grid item xs={2} sm={2} lg={1} md={1}>
                                <CmpCheckboxField xChecked={sSelectItemChecked} xOnChange={() => fFilterSelectedItems()} />
                            </Grid>
                        </Grid>
                    </Grid> */}

          {/* <Grid item xs={8} sm={8} lg={4} md={4}>
              <Grid
                container
                spacing={1}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={4} lg={5} md={5}>
                  <CmpTypographyField xcomponent={'span'} xText={'Invoice Amount'} />
                </Grid>
                <Grid item xs={1} sm={1} lg={1} md={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={7} sm={6} lg={5} md={5}>
                  <CmpInputField
                    xType={'number'} // text/number/password
                    xName={'Rounding'}
                    xReadOnly={false}
                    // xValue={rdxTransHead.invoiceAmt}
                    // xOnChange={(event) => { dispatch(updateTransHead({ ...rdxTransHead, invoiceAmt: event.target.value })) }}
                    xValue={sHeadObj.invoiceAmt}
                    xOnChange={(event) => {
                      setHeadObj({ ...sHeadObj, invoiceAmt: event.target.value });
                    }}
                    xError={false}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                    xTextAlign="center"
                  />
                </Grid>
              </Grid>
            </Grid> */}
          {vMediaQuery && (
            <Grid item xs={4} sm={4} lg={4} md={4}>
              <Grid
                container
                spacing={1}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                sx={{ alignItems: 'center' }}>
                <Grid item xs={7.5}>
                  <CmpTypographyField xcomponent={'span'} xText={'Selected Items'} />
                </Grid>
                <Grid item xs={1}>
                  <CmpTypographyField xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={2}>
                  <CmpCheckboxField
                    xChecked={sSelectItemChecked}
                    xOnChange={() => fFilterSelectedItems()}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className="mt-2">
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <div>
              {sItemList.length > 0 && (
                // <TransItemEditableGrid
                //     rowsData={sSelectItemChecked ? sFilterdItemList : sItemList}
                //     setUpdateRows={sSelectItemChecked ? setFilterdItemList : setItemList}
                //     editable={rdxTransHead.Status === 'ENTER'}
                // />
                <TransItemEditableGrid
                  rowsData={sItemList}
                  setUpdateRows={setItemList}
                  taxPurchase={sHeadObj.TaxPurchase}
                  editable={rdxTransHead.Status === 'ENTER'}
                  sSelectItemChecked={sSelectItemChecked}
                  sHideItemChecked={sHideItemChecked}
                  setGridEditing={setGridEditing}
                />
              )}
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          className="my-2 justify-content-between"
          sx={{ alignItems: 'center' }}>
          <Grid item xs={4} sm={4} lg={4} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              rowSpacing={2}
              sx={{ alignItems: 'end' }}>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={7} sm={7} lg={5} md={5}>
                    <CmpTypographyField xcomponent={'span'} xText={'Hide Purchase Items'} />
                  </Grid>
                  <Grid item xs={1} sm={1} lg={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={2} sm={2} lg={1} md={1}>
                    <CmpCheckboxField
                      xDisabled={rdxTransHead.Status === 'POST' ? true : false}
                      xChecked={sHideItemChecked}
                      xOnChange={() => fFilterPurchasedItems()}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={7} sm={7} lg={5} md={5}>
                    <CmpTypographyField xcomponent={'span'} xText={'Vendor O/S'} />
                  </Grid>
                  <Grid item xs={1} sm={1} lg={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={4} sm={4} lg={6} md={6}>
                    {/* <CmpTypographyField xcomponent={'span'} xText={Number(rdxTransHead.TRNS_Vendor_Os).toFixed(2)} /> */}
                    <CmpTypographyField
                      xcomponent={'span'}
                      xText={Number(sHeadObj.TRNS_Vendor_Os).toFixed(2)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 5 }}
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '10px'
                  }}>
                  <Grid item xs={5} sm={5} md={1}>
                    <CmpStatusDiv xStatus={rdxTransHead.Status} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} sm={6} lg={4} md={4}>
            <Grid
              container
              spacing={1}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              className=" text-end"
              sx={{ alignItems: 'end' }}>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={5} sm={5} lg={5} md={5}>
                    <CmpTypographyField xcomponent={'span'} xText={'Taxable'} />
                  </Grid>
                  <Grid item xs={1} sm={1} lg={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6} md={6}>
                    <CmpTypographyField
                      xcomponent={'span'}
                      xText={Number(sTotalObj.Taxable).toFixed(2)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={5} sm={5} lg={5} md={5}>
                    <CmpTypographyField xcomponent={'span'} xText={'Tax'} />
                  </Grid>
                  <Grid item xs={1} sm={1} lg={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6} md={6}>
                    <CmpTypographyField
                      xcomponent={'span'}
                      xText={Number(sTotalObj.Tax).toFixed(2)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={12} lg={12} md={12}>
                                <Grid
                                    container
                                    spacing={1}
                                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                    sx={{ alignItems: 'center' }}>
                                    <Grid item xs={5} sm={5} lg={5} md={5}>
                                        <CmpTypographyField xcomponent={'span'} xText={'Total'} />
                                    </Grid>
                                    <Grid item xs={1} sm={1} lg={1} md={1}>
                                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={6} md={6}>
                                        <CmpTypographyField xcomponent={'span'} xText={Number(sTotalObj.Total).toFixed(2)} />

                                    </Grid>
                                </Grid>
                            </Grid> */}
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={5} sm={5} lg={5} md={5}>
                    <CmpTypographyField xcomponent={'span'} xText={'Rounding'} />
                  </Grid>
                  <Grid item xs={1} sm={1} lg={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6} md={6}>
                    {/* <CmpTypographyField xcomponent={'span'} xText={Number(rdxTransHead.Rounding).toFixed(2)} /> */}
                    <CmpInputField
                      xType={'text'} // text/number/password
                      xName={'Rounding'}
                      xReadOnly={false}
                      // xValue={rdxTransHead.Rounding}
                      // xOnChange={(event) => { dispatch(updateTransHead({ ...rdxTransHead, Rounding: event.target.value })) }}
                      xValue={sHeadObj.Rounding}
                      xOnChange={(event) => {
                        setHeadObj({ ...sHeadObj, Rounding: event.target.value });
                      }}
                      xError={false}
                      xErrorMessage={''}
                      xOnKeyUp={''}
                      xTextAlign="right"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <Grid
                  container
                  spacing={1}
                  columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  sx={{ alignItems: 'center' }}>
                  <Grid item xs={5} sm={5} lg={5} md={5}>
                    <CmpTypographyField xcomponent={'span'} xText={'Net Amount'} />
                  </Grid>
                  <Grid item xs={1} sm={1} lg={1} md={1}>
                    <CmpTypographyField xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6} md={6}>
                    <CmpTypographyField
                      xcomponent={'span'}
                      xText={Number(sTotalObj.NetAmt).toFixed(2)}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {Number(rdxTransHead.TRNS_Vendor_Reduction) > 0 && (
                <>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Grid
                      container
                      spacing={1}
                      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                      sx={{ alignItems: 'center' }}>
                      <Grid item xs={5} sm={5} lg={5} md={5}>
                        <CmpTypographyField xcomponent={'span'} xText={'Adjustment'} />
                      </Grid>
                      <Grid item xs={1} sm={1} lg={1} md={1}>
                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6} md={6}>
                        <CmpTypographyField
                          xcomponent={'span'}
                          xText={Number(rdxTransHead.TRNS_Vendor_Reduction).toFixed(2)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Grid
                      container
                      spacing={1}
                      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                      sx={{ alignItems: 'center' }}>
                      <Grid item xs={5} sm={5} lg={5} md={5}>
                        <CmpTypographyField xcomponent={'span'} xText={'Balance'} />
                      </Grid>
                      <Grid item xs={1} sm={1} lg={1} md={1}>
                        <CmpTypographyField xcomponent={'span'} xText={':'} />
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6} md={6}>
                        <CmpTypographyField
                          xcomponent={'span'}
                          xText={(
                            Number(sTotalObj.NetAmt).toFixed(2) -
                            Number(rdxTransHead.TRNS_Vendor_Reduction).toFixed(2)
                          ).toFixed(2)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid
                    container
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 5 }}
                    sx={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                    <Grid item xs={5} sm={5} md={1}>
                        <CmpStatusDiv xStatus={rdxTransHead.Status} />
                    </Grid>
                </Grid> */}
      </Box>
      <Modal
        open={sShowPostModal}
        onClose={() => setShowPostModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="modal-purchase-Modal">
          <PurchasePostModal
            sHeadObj={sHeadObj}
            setHeadObj={setHeadObj}
            setShowPostModal={setShowPostModal}
            xTotal={Number(sTotalObj.Total).toFixed(2)}
          />
        </Box>
      </Modal>
      <Modal
        open={sShowAddItemModal}
        onClose={() => setShowAddItemModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="modal-purchase-Modal">
          <AddNewItemModal
            sItemList={sItemList}
            setItemList={setItemList}
            setShowAddItemModal={setShowAddItemModal}
            selectedSrNo={0}
            setHideItemChecked={setHideItemChecked}
          />
        </Box>
      </Modal>
    </div>
  );
}
