import { Delete, DeleteOutline, DeleteOutlined } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  useMediaQuery
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid';
import React, { useCallback, useState, useEffect } from 'react';
import { CmpSelectField } from '../../../component/ToolBox/ToolBox';
//bootstrap input mui
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { height } from '@mui/system';
import GCItemSearch from '../../../component/ReuseableComponent/GCItemSearch';
import { getGitterColumns } from '../../../Utility/Utilitys';
import {
  getStkTransItemSearch,
  getStkTransItem,
  deleteStkTransItem,
  tsrPackQtyApi
} from '../actions';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    //position: 'relative',
    backgroundColor: '',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow'])
  }
}));

export default function TransferItemGrid({
  xMobileScreen,
  xMediaQuery,
  xItemList,
  xSetItemList,
  rdxHeadObj,
  fAddItem,
  sItemSearchModal,
  setItemSearchModal,
  setSelectedID,
  selectedID,
  ItemPackQty,
  sItemPackQtyModal,
  setItemPackQtyModal
}) {
  // const [selectedID, setSelectedID] = useState(0);
  // const [sItemSearchModal, setItemSearchModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const handleEditCellChange = (params) => {
    if (params.field === 'PackQty') {
      setIsEditing(true);
    }
  };

  const handleEditCellChangeCommitted = (params) => {
    if (params.field === 'PackQty') {
      setIsEditing(false);
    }
  };
  const [sPkListRow, setPkListRow] = useState([]);
  const [sCurrentRowSelected, setCurrentRowSelected] = useState(false);
  const [sGridSelectRowData, setGridSelectRowData] = useState([]);
  const [sGridSelectColData, setGridSelectColData] = useState([]);
  const [sItemSearchModalObj, setItemSearchModalObj] = useState({
    vItemNameCode: '',
    vItemNameName: '',
    vItemNameBarcode: '',
    vItemNameRefNo: ''
  });
  const [sEnterKeyObj, setEnterKeyObj] = useState({
    key: 'Enter'
  });
  const [sLoadingItemSearch, setLoadingItemSearch] = useState(false);

  const setTSRQty = (params) => {
    let itemList = xItemList.map((i) => {
      return i.IL_ItmCd === params.row.IL_ItmCd
        ? {
            ...i,
            B_Tsr_Qty: params.value,
            B_Tsr_BaseQty: Number(params.value) * Number(i.B_Tsr_PackQty)
          }
        : i;
    });
    xSetItemList(itemList);
    return { ...params.row, FinalQty: params.value };
  };

  const setPackQty_V1 = (params, PkValue) => {
    // let unitObj = params.row.units.find(u => u.Unit === params.row.B_Tsr_Unit);
    // let unitRate = Number((Number(unitObj?.CostPr) * Number(params.value)).toFixed(3));

    let itemList = xItemList.map((i) => {
      const transferRatio = Number(i.SuggestedTransfer) / Number(PkValue);

      return i.IL_ItmCd === params.row.IL_ItmCd
        ? {
            ...i,
            B_Tsr_PackQty: PkValue,
            // B_Tsr_UnitRate: unitRate,
            B_Tsr_UnitRate: Number(Number(i?.CostPr) * Number(PkValue)),
            B_Tsr_BaseQty: Number(PkValue) * Number(i.B_Tsr_Qty),
            // SuggestedTransferCTN:
            //   Number(PkValue) == 0
            //     ? 0
            //     : Number(i.SuggestedTransfer) / Number(PkValue) > 0 &&
            //       Number(i.SuggestedTransfer) / Number(PkValue) < 0.99
            //     ? 1
            //     : Math.round(Number(i.SuggestedTransfer) / Number(PkValue)),
            //changes done on 11/102024 (along with shinji ,safia ,sir )
            SuggestedTransferCTN:
              Number(PkValue) === 0 || transferRatio == 0
                ? 0
                : transferRatio > 0 && transferRatio < 0.99
                ? 1
                : Math.round(transferRatio),
            IL_StockCTN: Number(PkValue) == 0 ? 0 : Number(i.IL_Stock) / Number(PkValue),
            IL_FinalQtyCTN:
              Number(PkValue) == 0
                ? 0
                : Number(i.IL_FinalQty) / Number(PkValue) > 0 &&
                  Number(i.IL_FinalQty) / Number(PkValue) < 0.99
                ? 1
                : Math.round(Number(i.IL_FinalQty) / Number(PkValue)),
            DCPickShowing: Number(PkValue) == 0 ? 0 : Number(i.DCPick) / Number(PkValue)
          }
        : i;
    });
    //SuggestedTransferCTN,IL_StockCTN newly added field
    xSetItemList(itemList);
  };
  const setPackQty_Modal = (params) => {
    // let unitObj = params.row.units.find(u => u.Unit === params.row.B_Tsr_Unit);
    // let unitRate = Number((Number(unitObj?.CostPr) * Number(params.value)).toFixed(3));

    let itemList = sPkListRow.map((i) => {
      return i.IL_ItmCd === params.row.IL_ItmCd
        ? {
            ...i,
            PackQty: params.value
          }
        : i;
    });
    //SuggestedTransferCTN,IL_StockCTN newly added field
    setPkListRow(itemList);
    return {
      ...params.row,
      PackQty: params.value
    };
  };

  const setTSRUnit = (params, unit) => {
    let unitObj = params.row.units.find((u) => u.Unit === unit);
    let packQty = Number(unitObj?.PackQty);
    // let unitRate = Number((Number(unitObj?.CostPr) * packQty).toFixed(3));

    let itemList = xItemList.map((i) => {
      const transferRatio = Number(i.SuggestedTransfer) / Number(packQty);

      return i.IL_ItmCd === params.row.IL_ItmCd
        ? {
            ...i,
            B_Tsr_Unit: unit,
            B_Tsr_PackQty: packQty,
            // B_Tsr_UnitRate: unitRate,
            B_Tsr_UnitRate: Number((Number(i?.CostPr) * packQty).toFixed(3)),
            B_Tsr_BaseQty: packQty * Number(i.B_Tsr_Qty),
            // SuggestedTransferCTN: Number(packQty) == 0 ? 0 : Number(i.SuggestedTransfer) / packQty,
            //changes done on 11/102024 (along with shinji ,safia ,sir )
            SuggestedTransferCTN:
              Number(packQty) === 0 || transferRatio == 0
                ? 0
                : transferRatio > 0 && transferRatio < 0.99
                ? 1
                : Math.round(transferRatio),

            IL_StockCTN: Number(packQty) == 0 ? 0 : Number(i.IL_Stock) / packQty,
            IL_FinalQtyCTN: Number(packQty) == 0 ? 0 : Number(i.IL_FinalQty) / packQty,
            DCPickShowing: Number(packQty) == 0 ? 0 : Number(i.DCPick) / Number(packQty)
          }
        : i;
    });
    //SuggestedTransferCTN,IL_StockCTN,IL_FinalQtyCTN newly added field
    xSetItemList(itemList);
    return { ...params.row, FinalQty: params.value };
  };

  const fSearchItem = async () => {
    let searchObj = {
      v_VocTyp: rdxHeadObj?.vocTyp,
      v_VocNo: rdxHeadObj?.vocNo,
      v_ItmCd: sItemSearchModalObj.vItemNameCode,
      v_ItmName: sItemSearchModalObj.vItemNameName,
      v_Barcode: sItemSearchModalObj.vItemNameBarcode,
      v_RefNo1: sItemSearchModalObj.vItemNameRefNo
    };

    const res = await getStkTransItemSearch(searchObj);
    return res.ResultSet;
  };

  const GetItemSearchValue = async (e, xOnchangeAPI, xValue) => {
    if (e.key == 'ArrowDown' || e.keyCode == 40) {
      setTimeout(() => {
        var selectedLength = document.getElementsByClassName('Mui-selected').length;
        if (selectedLength === 0) {
          let tabel = document.getElementById('table-main-grid-3');
          // vIsDownKeyPressed = true;
          // tabel?.getElementsByClassName('MuiDataGrid-row')[0]?.firstChild?.click();
          // tabel?.getElementsByClassName('MuiDataGrid-row')[0]?.firstChild?.dblclick();
          tabel?.getElementsByClassName('MuiDataGrid-row')[0]?.firstChild?.focus();
        }
      }, 500);
    } else if (e.key == 'Enter' || e._reactName === 'onClick') {
      const result = await fSearchItem();
      if (result.length > 0) {
        let GridSearchItem = result?.map((obj, i) => ({ ...obj, id: i + 1 }));
        setGridSelectRowData(GridSearchItem);
      }
    }
  };
  const fClearItemSearch = useCallback(
    (event) => {
      setItemSearchModalObj({
        vAnydata: '',
        vItemNameCode: '',
        vItemNameName: '',
        vItemNameBarcode: '',
        vItemNameRefNo: ''
      });
      setGridSelectRowData([]);
      setLoadingItemSearch(false);
    },
    [sItemSearchModalObj]
  );

  const fCloseItemSearchModal = () => {
    setItemSearchModal(false);
    fClearItemSearch();
  };
  const fCloseItempackQty = () => {
    setItemPackQtyModal(false);
    setCurrentRowSelected([]);
    setPkListRow([]);
  };

  const fonChangeInputModal = (event) => {
    setItemSearchModalObj({ ...sItemSearchModalObj, [event.target.name]: event.target.value });
  };
  // const fonChangeInputModalSelect = (event) => {
  //     setItemSearchModalObj({ ...sItemSearchModalObj, [event.target.name]: event.target.value });
  //     // GetItemSearchValue("", "GROUP", event.target.value);
  // };
  const fOnRowClickGCItemSearch = async (item) => {
    // dispatchItem(item.row.ItmCd, item.row.Name1);
    const payload = {
      v_VocTyp: rdxHeadObj?.vocTyp,
      v_VocNo: rdxHeadObj?.vocNo,
      v_ItmCd: item.row.ItmCd,
      Units: ''
    };
    const result = await getStkTransItem(payload);
    fAddItem(result?.ResultSet, selectedID);
    setItemSearchModal(false);
  };

  useEffect(() => {
    if (sItemSearchModal) {
      fShowItemSearchModal();
    }
  }, [sItemSearchModal]);

  const fShowItemSearchModal = async () => {
    const payload = {
      trnTyp: 'STCKTSFRITMSRCH'
    };
    const result = await getGitterColumns(payload);
    setGridSelectColData(result?.ResultSet?.columns);
    // setItemSearchModal(true);
  };
  const fOnCellClick = async (params) => {
    // console.log('params', params);
    if (params.field === 'ItemName' && params?.row?.IL_ItmCd == '') {
      setSelectedID(params.row.id);
      setItemSearchModal(true);
      // fShowItemSearchModal();
    }
    if (params.field === 'B_Tsr_PackQty') {
      setCurrentRowSelected(params);
      let iPkQty = await tsrPackQtyApi({
        v_Loc: rdxHeadObj.dcLoc,
        v_itmCd: params.row.IL_ItmCd
      });
      if (iPkQty.ResultSet?.Item?.length > 0) {
        let GridSearchItem = iPkQty.ResultSet?.Item?.map((obj, i) => ({ ...obj, id: i + 1 }));
        setPkListRow(GridSearchItem);
      } else {
        setPkListRow([
          {
            id: 1,
            Party: '',
            PartyName: '',
            Date: new Date(),
            PackQty: 1
          }
        ]);
      }
      setItemPackQtyModal(true);
    }
  };
  const onClickDelete = async (params) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to Delete?',
      buttons: [
        {
          className: 'alertBtn',
          label: 'Yes',
          onClick: async () => {
            xSetItemList(xItemList.filter((x) => x.id != params.row.id));
            const payload = {
              vocType: rdxHeadObj?.vocTyp,
              vocNo: rdxHeadObj?.vocNo,
              loc: rdxHeadObj.loc,
              itmCd: params.row.IL_ItmCd
            };
            const result = await deleteStkTransItem(payload);
            console.log(result, 'result');
          }
        },
        {
          className: 'alertBtn',
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const columns = [
    {
      field: 'IL_ItmCd',
      headerName: 'Item Code',
      // flex: 2,
      hide: xMobileScreen,
      width: xMediaQuery ? 100 : 150
    },
    {
      field: 'ItemName',
      headerName: 'Item Name',
      // flex: 5
      width: xMediaQuery ? 120 : 200
    },

    {
      field: 'B_Tsr_PackQty',
      headerName: 'Pack Qty',
      // flex: 2,
      width: xMediaQuery ? 50 : 100,
      align: 'center',
      headerAlign: 'center',
      hide: false
      // valueSetter: setPackQty,
      // editable: true,
      // type: 'number',
      // cellClassName: 'mui-request-grid-final-qty'
    },

    {
      field: 'B_Tsr_Unit',
      headerName: 'Unit',
      // flex: 3,
      align: 'center',
      headerAlign: 'center',
      hide: true,
      editable: true,
      cellClassName: 'mui-transfer-grid-unit',
      width: xMediaQuery ? 70 : 120,

      //type: 'singleSelect',
      //valueOptions: typeData,
      renderCell: (params) => {
        return (
          <div style={{ width: '100%', height: '100%', padding: 0 }}>
            {/* <CmpSelectField
                            xLabelText="unit"
                            xValue={params.row.B_Tsr_Unit}
                            xOnChange={(event) => { setTSRUnit(params, event.target.value) }}
                            xName={'unit'}
                            xData={params.row.unitList}
                            xValueMember={''}
                            xDispalyMember={''}
                        // xError={sErrorMsg.locCd !== ''}
                        // xErrorMessage={sErrorMsg.locCd}
                        //xDisable={sHeadObj.vocNo !== ''}
                        /> */}
            <Select
              value={params.row.B_Tsr_Unit}
              label={'unit'}
              onChange={(event) => {
                setTSRUnit(params, event.target.value);
                console.log(event);
              }}
              name={'unit'}
              fullWidth
              style={{ padding: 0, height: '100%', textAlign: 'center' }}
              input={<BootstrapInput />}>
              {params.row.units.length > 0 ? (
                params.row.units.map((e, i) => {
                  const keys = Object.keys(e);
                  return (
                    <MenuItem key={'Key' + i} value={e.Unit}>
                      {e.UnitName}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem value={''}>none</MenuItem>
              )}
            </Select>
          </div>
        );
      }
    },

    {
      field: 'IL_Stock',
      headerName: 'Stock',
      // flex: 1,
      align: 'center',
      headerAlign: 'center',
      hide: true,
      valueFormatter: ({ value }) => Number(value).toFixed(0),
      width: xMediaQuery ? 50 : 80
    },
    {
      field: 'IL_StockCTN',
      headerName: 'Stock',
      // flex: 1,
      align: 'center',
      headerAlign: 'center',
      hide: xMobileScreen,
      valueFormatter: ({ value }) => Number(value).toFixed(0),
      width: xMediaQuery ? 50 : 80
    },
    {
      field: 'IL_FinalQty',
      headerName: xMediaQuery ? 'Req' : 'Req Qty',
      // flex: 2,
      hide: true,
      align: 'center',
      headerAlign: 'center',
      // valueFormatter: ({ value }) => Number(value).toFixed(0),
      width: xMediaQuery ? 50 : 80
    },
    {
      field: 'DCPick',
      headerName: 'DCPickNormal',
      // flex: 2,
      hide: true,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: ({ value }) => Number(value).toFixed(0),
      width: xMediaQuery ? 50 : 80
    },
    {
      field: 'DCPickShowing',
      headerName: 'DCPick',
      // flex: 2,
      hide: false,
      align: 'center',
      headerAlign: 'center',
      // valueFormatter: ({ value }) => Number(value).toFixed(0),
      valueFormatter: ({ value }) => Number(value).toFixed(2),
      width: xMediaQuery ? 50 : 100
    },
    {
      field: 'IL_FinalQtyCTN',
      headerName: xMediaQuery ? 'Req' : 'Req Qty',
      // flex: 2,
      align: 'center',
      headerAlign: 'center',
      // valueFormatter: ({ value }) => Number(value).toFixed(0),
      width: xMediaQuery ? 50 : 80
    },
    {
      field: 'PurchaseRatio',
      headerName: 'Ratio of Purchase',
      flex: 2,
      align: 'center',
      headerAlign: 'center',
      hide: true,
      valueFormatter: ({ value }) => (value > 0 ? Number(value).toFixed(2) : 0)
    },
    {
      field: 'IL_OrderQty',
      headerName: 'Order Qty',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      // hide: xMobileScreen,
      hide: true,
      valueFormatter: ({ value }) => Number(value).toFixed(0)
    },
    {
      field: 'IL_AvgSales',
      headerName: 'Avg. Sales',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      // hide: xMobileScreen,
      hide: true,
      valueFormatter: ({ value }) => Number(value).toFixed(0)
    },
    {
      field: 'SuggestedTransfer',
      headerName: 'Sugges.Qty',
      flex: 2,
      align: 'right',
      headerAlign: 'right',
      hide: true,
      valueFormatter: ({ value }) => (value > 0 ? Number(value).toFixed(2) : 0)
      // valueFormatter: ({ value }) => (value > 0 ? Math.round(value) : Number(value).toFixed(0))
    },
    {
      field: 'SuggestedTransferCTN',
      headerName: 'Suggest',
      // flex: 2,
      align: 'center',
      headerAlign: 'center',
      // valueFormatter: ({ value }) => (value > 0 ? Number(value).toFixed(2) : 0),
      // valueFormatter: ({ value }) => (value > 0 ? Math.round(value) : 1),

      width: xMediaQuery ? 50 : 80
    },

    {
      field: 'B_Tsr_Qty',
      headerName: 'Qty',
      // flex: 2,
      align: 'center',
      headerAlign: 'center',
      hide: false,
      valueSetter: setTSRQty,
      editable: true,
      type: 'number',
      cellClassName: 'mui-request-grid-final-qty',
      width: xMediaQuery ? 50 : 80
    },

    {
      field: 'B_Tsr_BaseQty',
      headerName: 'Base Qty',
      // flex: 1,
      align: 'center',
      headerAlign: 'center',
      hide: xMediaQuery,
      width: xMediaQuery ? 50 : 80
    },
    {
      field: 'B_Tsr_UnitRate',
      headerName: 'Unit rate',
      // flex: 2,
      align: 'right',
      headerAlign: 'right',
      width: xMediaQuery ? 50 : 80,
      valueFormatter: ({ value }) => (value > 0 ? Number(value).toFixed(2) : 0)
    },
    {
      field: 'delete',
      headerName: '',
      // flex: 1,
      align: 'center',
      headerAlign: 'center',
      hide: false,
      width: xMediaQuery ? 50 : 150,

      renderCell: (params) => {
        return (
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              onClickDelete(params);
            }}
            disabled={
              rdxHeadObj.status.toString().toUpperCase() !== 'ENTER' || params.row.IL_Type == 'Auto'
            }>
            <DeleteOutline />
          </IconButton>
        );
      }
    }
  ];

  // console.log(xItemList,"xItemList");
  return (
    <>
      <Box
        sx={{
          height: xMediaQuery ? (xMobileScreen ? '59vh' : '61vh') : '62vh',
          width: '100%',
          '& .actions': {
            color: 'text.secondary'
          },
          '& .textPrimary': {
            color: 'text.primary'
          }
        }}>
        <DataGrid
          getRowId={(row) => row.id}
          rowHeight={40}
          headerHeight={40}
          disableColumnSelector
          sx={{
            border: 2,
            borderColor: 'primary.light'
            // color: '#000000'
          }}
          componentsProps={{
            filterPanel: { sx: { maxWidth: '93vw' } }
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
          }
          rows={xItemList}
          columns={columns}
          onCellClick={(params) => {
            fOnCellClick(params);
          }}
        />
      </Box>
      <Modal
        open={sItemSearchModal}
        onClose={fCloseItemSearchModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="model-sales-invoice-GCVanMaster-Modal">
          <GCItemSearch
            fCloseItemSearch={fCloseItemSearchModal}
            title={'Item'}
            xOnRowClick={(item) => fOnRowClickGCItemSearch(item)}
            xItemRows={sGridSelectRowData}
            xItemColumn={sGridSelectColData}
            xonChangeInputModal={fonChangeInputModal}
            fonChangeInputModalSelect={() => {}}
            xItemSearchModalObj={sItemSearchModalObj}
            xfClear={fClearItemSearch}
            xGetItemSearchValue={GetItemSearchValue}
            sLoadingItemSearch={sLoadingItemSearch}
            vMediaQuery={xMediaQuery}
            xEnterObj={sEnterKeyObj}
            vRdxDBLocationGroup={[]}
            xanyData={false}
            xgroup={false}
            xnameFL={false}
          />
        </Box>
      </Modal>
      <Modal
        open={sItemPackQtyModal}
        onClose={fCloseItempackQty}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="model-sales-invoice-GCVanMaster-Modal">
          <Box sx={{ flexGrow: 1 }} className="gnr-item-search  GCItemSearch-Modal">
            <Grid container spacing={0} className="grnt-item-search-title">
              <Grid item xs={12} className="item-search d-flex justify-content-between">
                <span> Packing List</span>
                <CloseIcon className="grnt-item-search-close-icon" onClick={fCloseItempackQty} />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="search-item mt-2">
              <Grid item xs={12} className=" height-table-scroll " component="div">
                <div
                  style={{ height: xMediaQuery ? '45vh' : '50vh', width: '100%' }}
                  id="table-main-grid-3"
                  className="search-grid-table ">
                  <Box
                    style={{ height: '100%', width: '100%' }}
                    className="bintransfer-out-grid px-2">
                    <DataGrid
                      rowHeight={30}
                      headerHeight={40}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
                      }
                      sx={{
                        border: 1,
                        borderColor: 'primary.light'
                      }}
                      componentsProps={{
                        filterPanel: { sx: { maxWidth: '93vw' } }
                      }}
                      rows={sPkListRow}
                      columns={[
                        {
                          field: 'PackQty',
                          headerName: 'PackQty',
                          hide: false,
                          width: 90,
                          align: 'center',
                          headerAlign: 'center',
                          Typ: 'S',
                          // NumberFormat: '0',
                          valueSetter: setPackQty_Modal,
                          editable: true,
                          type: 'number',
                          cellClassName: 'mui-request-grid-final-qty'
                        },

                        {
                          field: 'Party',
                          headerName: 'PartyCd',
                          hide: true,
                          width: 100,
                          align: 'left',
                          headerAlign: 'left',
                          Typ: 'S',
                          NumberFormat: '0'
                        },
                        {
                          field: 'PartyName',
                          headerName: 'Party',
                          hide: false,
                          width: 400,
                          align: 'left',
                          headerAlign: 'left',
                          Typ: 'S',
                          NumberFormat: '0'
                        },
                        {
                          field: 'Date',
                          headerName: 'Date',
                          hide: false,
                          width: 100,
                          align: 'left',
                          headerAlign: 'left',
                          Typ: 'S',
                          NumberFormat: '0',
                          valueFormatter: ({ value }) => moment(value).format('DD/MM/YYYY')
                        },
                        {
                          field: 'delete',
                          headerName: '',
                          // flex: 1,
                          align: 'center',
                          headerAlign: 'center',
                          hide: false,
                          width: xMediaQuery ? 50 : 150,
                          editable: true,
                          renderCell: (params) => {
                            return (
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => {
                                  setPackQty_V1(sCurrentRowSelected, params.row.PackQty);
                                  fCloseItempackQty();
                                }}
                                disabled={isEditing}>
                                <SaveIcon />
                              </IconButton>
                            );
                          }
                        }
                      ]}
                      getRowId={(row) => row.id}
                      hideFooter
                      // onColumnHeaderEnter={}
                      // onCellKeyDown={xOnCellKeyDown}
                      onRowClick={(params) => {
                        // console.log('safdasd', sCurrentRowSelected, params.row.PackQty);
                        // setPackQty_V1(sCurrentRowSelected, params.row.PackQty);
                        // fCloseItempackQty();
                      }}
                      onCellEditStart={handleEditCellChange}
                      onCellEditStop={handleEditCellChangeCommitted}
                    />
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
